import ButtonToggleSwitch from 'components/buttons/button-toggle-switch';
import FontIcon from 'components/icons';
import Signups from 'components/modal/signups';

import classnames from 'classnames';

import './styles/pricing-card.scss';

type PricingTierSlideProps = {
  activateAttendance: boolean
  fdt285?: string | boolean,
  fdt387?: string | boolean,
  tier: PricingCard,
  toggleAttendance: () => void
}

export type PricingCard = {
    attendance_toggle: boolean,
    features: {
      heading: string,
      list: {
        feature: string,
      }[],
    },
    features_alt: {
      heading: string,
      list: {
        feature: string,
      }[],
    },
    for_who: string,
    price: string,
    price_label: string,
    price_with_attendance: string,
    title: string
}

const PricingCard = ({
  activateAttendance,
  toggleAttendance,
  tier,
  fdt285,
  fdt387,
}: PricingTierSlideProps) => {

  let features = tier.features;
  if (fdt387) {
    features = tier.features_alt;
  }

  const priceDisplay = price => {
    let newPrice = price;
    // Temporarily cheating to get the 2024v2 pricing instead of digging through the old pricing object.
    // Can be removed when 2024v2 pricing is fully rolled out and WP Pricing & Packaging content has been updated.
    if (fdt285 === 'pricing-2024-v2') {
      switch (price) {
        case '2.50':
          newPrice = '1.50';
          break;
        case '4.00':
          newPrice = '3.00';
          break;
        case '5.00':
          newPrice = '3.00';
          break;
        case '7.00':
          newPrice = '5.00';
          break;
        case '8.00':
          newPrice = '5.00';
          break;
        case '10.00':
          newPrice = '7.00';
          break;
      }
    }
    const [dollars, cents] = newPrice.split('.');
    if (cents === '00') {
      return `$${dollars}`; 
    }
    
    return <div className="price">${dollars}<span>.{cents}</span></div>;
  }

  return (
    <div className={ classnames("column pricing-card-slide", { 'has-free-plan': fdt387 })}>
      <div className="card-wrapper">
        <div className="card has-shadow">
          <h3 className="tier-title">{tier.title}</h3>
          <h4 className="price-heading">
            { priceDisplay(activateAttendance ? tier.price_with_attendance : tier.price) }
            <span className="price-label"> {tier.price_label}</span>
          </h4>

          <p className="tier-for-who">{tier.for_who}</p>

          { tier.attendance_toggle ? (
            <button type="button" className="ta-toggle" onClick={ toggleAttendance }>
              Time Tracking &amp; Attendance
              <ButtonToggleSwitch 
                toggleState={ activateAttendance }
                id="priceSwitch-tiers"
                label="Add Time Tracking &amp; Attendance"
              />
            </button>
          ) : (
            <div className="ta-not-available">Time Tracking & Attendance not available</div>
          )}

          <div className="signups-wrapper">
            <Signups
              fieldLayout="row"
              buttonClasses="button submit-button is-rounded"
              showModalButton={ true }
              signupModalLaunch="Start my free trial"
            />
          </div>

          <h4 className="features-heading">{features.heading}</h4>
          <ul className="features-list">
            {features && features.list.map((feature, index) => (
              <li key={ index }><FontIcon icon="checkmark" /> {feature.feature}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
