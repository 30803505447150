import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { flatten } from 'lodash';

import classnames from 'classnames';
import PricingCardSlide, { type PricingCard } from './slides/pricing-card';

type PricingTiersProps = {
  activateAttendance: boolean,
  fdt285?: string | boolean,
  fdt387?: string | boolean,
  tiers: PricingCard[],
  toggleAttendance: () => void
}

const PricingTiers = ({
  activateAttendance,
  toggleAttendance,
  tiers,
  fdt285,
  fdt387,
}: PricingTiersProps) => {
  const breakpoints = useBreakpoint();

  const [itemIndex, setItemIndex] = useState(0);

  const tabNav = () => {
    return <nav className="wiw-tabs-nav">
      {tiers.map((tier, index) => {
        return (
          <button
            key={ index }
            role="button"
            className={ `button wiw-tabs-link is-rounded ${
              index === itemIndex ? 'active' : ''
            }` }
            onClick={ () => setItemIndex(index) }
          >
            {tier.title ? tier.title : tier}
          </button>
        );
      })}
    </nav>;
  };

  const selectNav = () => {
    return <nav className="wiw-tabs-nav">
      <div className="select is-rounded">
        <select value={ itemIndex } onChange={ e => setItemIndex(parseInt(e.currentTarget.value)) }>
          {tiers.map((tier, index) => {
            return <option value={ index } key={ index }>{ tier.title ? tier.title : tier }</option>;
          })}
        </select>
      </div>
    </nav>;
  };

  if (breakpoints.sm) {
    return (
      <div className="columns slider-container pricing-tiers-slider">
        { !fdt387 ? tabNav() : selectNav() }
        <SwipeableViews
          onChangeIndex={ i => setItemIndex(i) }
          style={ { padding: '0px calc(42% - 150px)' } }
          springConfig={ {
            easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
            duration: '.8s',
            delay: '0s',
          } }
          enableMouseEvents
          index={ itemIndex }
        >
          {tiers?.map((tier, index) => {
            return (
              <PricingCardSlide
                key={ index }
                toggleAttendance={ toggleAttendance }
                activateAttendance={ activateAttendance }
                tier={ tier }
                fdt285={ fdt285 }
                fdt387={ fdt387 }
              />
            );
          })}
        </SwipeableViews>
        <div className="slides-dots">
          {new Array(flatten(tiers?.map(data => data)).length)
            .fill(undefined)
            .map((_, index) => (
              <div
                key={ index }
                className={ `slides-dot ${
                  index === itemIndex ? 'slides-dot-fill' : 'slides-dot-empty'
                }` }
              />
            ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className={ classnames("columns tiers-container", { 'has-free-plan': fdt387 })}>
        {tiers?.map((tier, index) => {
          return (
            <PricingCardSlide
              key={ index }
              toggleAttendance={ toggleAttendance }
              activateAttendance={ activateAttendance }
              tier={ tier }
              fdt285={ fdt285 }
              fdt387={ fdt387 }
            />
          );
        })}
      </div>
    );
  }
};

export default PricingTiers;
